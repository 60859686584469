import { Controller } from "@hotwired/stimulus";
import Swiper from "swiper";
import { Navigation, Pagination } from 'swiper/modules';

export default class extends Controller {
  static targets = ["container", "pagination", "buttonNext", "buttonPrev"];

  connect() {
    this.swiper = new Swiper(this.containerTarget, {
      modules: [Navigation, Pagination],
      slidesPerView: 'auto',
      slidesPerGroup: 2,
      spaceBetween: 25,
      centerInsufficientSlides: true,
      loop: false,
      watchOverflow: true,
      pagination: {
        el: this.paginationTarget,
        clickable: true,
      },
      navigation: {
        nextEl: this.buttonNextTarget,
        prevEl: this.buttonPrevTarget,
      },
      on: {
        slideChangeTransitionEnd: function () {
          var activeSlide = $(".swiper-slide-active").find(".report-assessment-images");
          if (activeSlide.length) {
            reportHandler.setReportAssessmentImageAsActive(activeSlide);
          }
        }
      },
    });
  }
}
